<template>
    <section
        :id="sliceId"
        class="ImageWithContent"
        :class="[
            'ImageWithContent--align-' +
                (slice.primary.media_alignment
                    ? slice.primary.media_alignment.toLowerCase()
                    : 'right'),
            'ImageWithContent--' + slice.variation,
            {
                'ImageWithContent--no-desc':
                    slice.variation === 'default' &&
                    !slice.primary.description.length,
                'ImageWithContent--no-image':
                    props.slice.variation === 'default' && !activeImage?.url,
            },
        ]"
        ref="imageWithContent"
    >
        <div class="ImageWithContent__content" data-speed="clamp(1.3)">
            <div class="ImageWithContent__content-wrapper">
                <template v-if="slice.variation === 'default'">
                    <h2 class="ImageWithContent__title">
                        <FillTextEffect
                            :id="sliceId"
                            :trigger-container="'#' + sliceId"
                            :text="slice.primary.title as string"
                        />
                    </h2>
                    <h3 class="ImageWithContent__subtitle">
                        {{ slice.primary.subtitle }}
                    </h3>
                    <PrismicRichText
                        v-if="slice.primary.description.length"
                        :field="slice.primary.description"
                        class="ImageWithContent__desc"
                    />
                    <div class="ImageWithContent__btn">
                        <CustomButton
                            v-if="slice.primary.button_text"
                            :href="slice.primary.button_external_link.url"
                            :to="slice.primary.button_internal_link.url"
                        >
                            {{ slice.primary.button_text }}
                        </CustomButton>
                    </div>
                </template>
                <template v-else-if="slice.variation === 'clickTitles'">
                    <ul class="ImageWithContent__clickTitles-list">
                        <li
                            v-for="(item, index) in internalisedItems"
                            :key="index"
                            class="ImageWithContent__clickTitles-item"
                        >
                            <h2
                                class="ImageWithContent__title"
                                :class="{
                                    'ImageWithContent__title-active':
                                        activeItemId === item.id,
                                }"
                                @click="
                                    activeItemId =
                                        activeItemId === item.id ? '' : item.id
                                "
                                role="button"
                                @mouseover="
                                    cursorStore.setCursorType('link-hover')
                                "
                                @mouseleave="
                                    cursorStore.setCursorType('default')
                                "
                            >
                                <span class="ImageWithContent__title-text">
                                    <FillTextEffect
                                        :id="sliceId"
                                        :trigger-container="'#' + sliceId"
                                        bottom-position="50%"
                                        :text="item.title as string"
                                    />
                                </span>
                            </h2>
                            <Transition
                                name="accordion"
                                @enter="start"
                                @after-enter="end"
                                @before-leave="start"
                                @after-leave="end"
                            >
                                <div
                                    :aria-hidden="
                                        (item.id !== activeItemId).toString()
                                    "
                                    :id="`control-${item.id}`"
                                    v-show="item.active"
                                >
                                    <PrismicRichText
                                        v-if="item.description.length"
                                        :field="item.description"
                                        class="ImageWithContent__desc"
                                    />
                                </div>
                            </Transition>
                        </li>
                    </ul>
                    <div class="ImageWithContent__gallery">
                        <div
                            class="splide"
                            :id="
                                'splide' +
                                '-' +
                                slice.id.replace('$', '-').replace('_', '-')
                            "
                            :style="'--slide-count:' + (splide.length || 4)"
                            aria-label="Splide Basic HTML Example"
                        >
                            <div class="splide__track">
                                <ul class="splide__list">
                                    <!-- Slides -->
                                    <div
                                        class="splide__slide"
                                        v-for="(
                                            item, index
                                        ) in internalisedItems"
                                        :key="index"
                                    >
                                        <SmartPicture
                                            :small-image="
                                                item.image?.mobile?.url
                                            "
                                            :medium-image="
                                                item.image?.mobile?.url
                                            "
                                            :large-image="item.image?.url"
                                            :with-placeholder="true"
                                            :dimensions="{
                                                width: 389,
                                                height: 218,
                                            }"
                                        />
                                        <h2
                                            class="ImageWithContent__gallery-title"
                                        >
                                            {{ item.title }}
                                        </h2>
                                        <PrismicRichText
                                            v-if="item.description.length"
                                            :field="item.description"
                                            class="ImageWithContent__desc"
                                        />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div
            v-if="activeImage?.url"
            class="ImageWithContent__media"
            data-speed="clamp(1.1)"
        >
            <SmartPicture
                :is-background="true"
                :small-image="activeImage.mobile.url"
                :medium-image="activeImage.url"
                :large-image="activeImage.url"
                class="ImageWithContent__media-image"
                :small-breakpoint="719"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'
import Splide from '@splidejs/splide'
// import '@splidejs/splide/css/core'
import '@splidejs/splide/css/core'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.ImageWithContentSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const cursorStore = useCursorStore()
const activeItemId = ref('')
const splide = ref({})
const sliceId = props.slice.id.replace('$', '-') + '-image-with-content'
const imageWithContent = ref()

const transformedItems = computed(() => {
    return props.slice.items
        .filter((item) => item.title)
        .map((item) => {
            return {
                id: item.title.replace(' ', '-').toLowerCase(),
                ...item,
            }
        })
})

const internalisedItems = computed(() => {
    return transformedItems.value.map((item) => {
        return {
            active: item.id === activeItemId.value,
            ...item,
        }
    })
})

const activeItem = computed(() => {
    return internalisedItems.value.filter((item) => item.active)[0]
})

const firstItemWithAnImage = computed(() => {
    return internalisedItems.value.filter((item) => item.image.url)[0]
})

const activeImage = computed(() => {
    if (props.slice.variation === 'default') {
        return props.slice.primary.image
    } else if (props.slice.variation === 'clickTitles') {
        if (activeItem.value) {
            return activeItem.value.image.url
                ? activeItem.value.image
                : firstItemWithAnImage.value.image
        }

        return transformedItems.value[0]?.image
    }
})

onMounted(() => {
    if (props.slice.variation === 'clickTitles') {
        try {
            initSplide()
        } catch (e) {}
    }
})

function start(el: any) {
    el.style.height = el.scrollHeight + 'px'
}

function end(el: any) {
    el.style.height = ''
}

function initSplide() {
    splide.value = new Splide(
        `#splide-${props.slice.id.replace('$', '-').replace('_', '-')}`,
        {
            arrows: false,
            classes: {
                pagination: 'splide__pagination ImageWithContent__pagination',
                page: 'splide__pagination__page ImageWithContent__pagination-item',
            },
        },
    ).mount()
}
</script>

<style lang="scss">
.TitleTextBlock + .ImageWithContent {
    @include large-up {
        padding-top: 2rem;
    }
}

.ImageWithContent:has(+ .VideoBlock) {
    @include small-only {
        margin-bottom: 3rem;
    }
}

.ImageWithContent {
    display: flex;
    flex-direction: column;
    background-color: var(--black);
    padding-top: 8rem;
    grid-column: 2 / span 22;

    @media (min-width: 720px) {
        flex-direction: row;
        align-items: center;
        grid-column: 2 / span 23;
    }
}

.ImageWithContent__title {
    @include heading-2;

    display: inline-block;
    margin-top: 1.5rem;

    &:hover {
        .ImageWithContent__title-text:after {
            width: 100%;
        }
    }

    @media (min-width: 720px) {
        margin-top: 0;
    }
}

.ImageWithContent__title-active {
    .ImageWithContent__title-text:after {
        width: 100%;
    }
}

.ImageWithContent__title-text {
    display: inline-block;

    &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: var(--white);
        transition: width 0.3s ease-out;
    }
}

.ImageWithContent__subtitle {
    @include body-lg;

    margin-top: 1.25rem;
    font-weight: var(--font-weight-semibold);

    @media (min-width: 720px) {
        @include body-md;
    }
}

.ImageWithContent__content {
    display: flex;
    justify-content: center;
    width: 100%;
    order: 2;

    @media (min-width: 720px) {
        order: 1;
        width: 60%;
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.ImageWithContent__content-wrapper {
    max-width: 37rem;
}

.ImageWithContent__desc {
    margin-top: 1.25rem;

    a {
        color: var(--lime-green);
    }
}

.ImageWithContent__btn {
    margin-top: 1.25rem;
}

.ImageWithContent__media {
    position: relative;
    width: 100%;
    order: 1;

    @media (min-width: 720px) {
        order: 2;
        width: 40%;
    }

    &::before {
        position: absolute;
        content: '';
        display: block;
        background: var(--black-30);
        top: -2%;
        left: -2%;
        width: 100%;
        height: 100%;
    }
}

.ImageWithContent__media-image {
    position: relative;
    width: 100%;
}

/**

Variations

*/

.ImageWithContent--clickTitles {
    grid-column: 1 / span 24;
    justify-content: center;
    padding-top: 3rem;

    .ImageWithContent__media {
        display: none;

        @include large-up {
            display: block;
        }
    }

    .ImageWithContent__title {
        margin-bottom: 1.25rem;
    }

    .ImageWithContent__desc {
        @include body-lg;

        margin-top: 0;
    }

    .ImageWithContent__content-wrapper {
        width: 100%;

        max-width: none;

        @include large-up {
            max-width: 37rem;
        }
    }

    .ImageWithContent__content {
        @include small-only {
            width: auto;
        }

        @include medium-only {
            width: 100%;
            padding: 0;
        }
    }

    .ImageWithContent__clickTitles-list {
        display: none;

        @include large-up {
            display: flex;
            flex-direction: column;
            row-gap: 2.5rem;
        }
    }

    .ImageWithContent__gallery {
        @include large-up {
            display: none;
        }

        .splide__slide {
            padding-left: 3%;
            padding-right: 3%;

            @include medium-only {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }

        .ImageWithContent__gallery-title {
            @include heading-2;

            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        .ImageWithContent__desc {
            @include body-lg;

            margin-top: 1.5rem;
        }

        .ImageWithContent__pagination {
            position: static;
            display: flex;
            column-gap: 0.5rem;
            width: 100%;
            justify-content: center;
            margin-top: 2.75rem;
            flex-wrap: nowrap;
            padding-left: 3%;
            padding-right: 3%;

            & li {
                width: calc(1 / var(--slide-count) * 100%);
                max-width: 5.25rem;
            }
        }

        .ImageWithContent__pagination-item {
            height: 0.25rem;
            background-color: var(--black-50);
            border-radius: 0;
            margin: 0;
            width: 100%;
            border: 0;
            transform: none;

            &.is-active {
                background-color: var(--white);
            }
        }
    }
}

.ImageWithContent--no-desc {
    .ImageWithContent__btn {
        margin-top: 2rem;
    }
}

.ImageWithContent--align-left {
    .ImageWithContent__media {
        order: 1;

        &::before {
            left: 2%;
        }
    }

    .ImageWithContent__content {
        order: 2;
    }

    &.ImageWithContent--no-image {
        justify-content: flex-end;
    }
}

/**

Transitions

*/

.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition:
        height 0.5s ease,
        opacity 0.5s ease;
    overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}
</style>
